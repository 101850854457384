import Vue from 'vue'
import VueRouter from 'vue-router'

import Backoffice from '../views/Backoffice.vue'
import Login from '../views/Auth/Login.vue'

import Dashboard from '../views/Dashboard.vue'

import GameHosts from '../views/GameHosts.vue'
import GameHostsList from '../views/GameHosts/GameHostsList.vue'
import GameHostsAdd from '../views/GameHosts/GameHostsAdd.vue'
import GameHostsEdit from '../views/GameHosts/GameHostsEdit.vue'
import GameHost from '../views/GameHosts/GameHost.vue'

import GameCountries from '../views/GameCountries.vue'
import GameCountriesList from '../views/GameCountries/GameCountriesList.vue'
import GameCountriesAdd from '../views/GameCountries/GameCountriesAdd.vue'
import GameCountriesEdit from '../views/GameCountries/GameCountriesEdit.vue'

import GameVariables from '../views/GameVariables.vue'
import GameVariablesList from '../views/GameVariables/GameVariablesList.vue'
import GameVariablesAdd from '../views/GameVariables/GameVariablesAdd.vue'
import GameVariablesEdit from '../views/GameVariables/GameVariablesEdit.vue'

import GameLanguages from '../views/GameLanguages.vue'
import GameLanguagesList from '../views/GameLanguages/GameLanguagesList.vue'
import GameLanguagesAdd from '../views/GameLanguages/GameLanguagesAdd.vue'
import GameLanguagesEdit from '../views/GameLanguages/GameLanguagesEdit.vue'

import AdminLanguages from '../views/AdminLanguages.vue'
import AdminLanguagesList from '../views/AdminLanguages/AdminLanguagesList.vue'
import AdminLanguagesAdd from '../views/AdminLanguages/AdminLanguagesAdd.vue'
import AdminLanguagesEdit from '../views/AdminLanguages/AdminLanguagesEdit.vue'

import HostGames from '../views/HostGames.vue'
import HostGamesList from '../views/HostGames/HostGamesList.vue'
import HostGamesAdd from '../views/HostGames/HostGamesAdd.vue'
import HostGamesControl from '../views/HostGames/HostGamesControl.vue'

import Stats from '../views/Stats.vue'
import Final from '../views/Final.vue'

Vue.use(VueRouter);

const routes = [
    {
        path: '/login',
        name: 'Login',
        component: Login,
    },
    {
        path: '/',
        name: 'Backoffice',
        component: Backoffice,
        children: [
            {
                path: '',
                name: 'Dashboard',
                component: Dashboard
            },


            /* Admin Routes */
            {
                path: 'hosts',
                name: 'GameHosts',
                component: GameHosts,
                children: [
                    {
                        path: '',
                        name: 'GameHostsList',
                        component: GameHostsList
                    },
                    {
                        path: 'add',
                        name: 'GameHostsAdd',
                        component: GameHostsAdd
                    },
                    {
                        path: 'edit/:id',
                        name: 'GameHostsEdit',
                        component: GameHostsEdit
                    },
                    {
                        path: 'info/:id',
                        name: 'GameHost',
                        component: GameHost
                    },
                ]
            },
            {
                path: 'countries',
                name: 'GameCountries',
                component: GameCountries,
                children: [
                    {
                        path: '',
                        name: 'GameCountriesList',
                        component: GameCountriesList
                    },
                    {
                        path: 'add',
                        name: 'GameCountriesAdd',
                        component: GameCountriesAdd
                    },
                    {
                        path: 'edit/:countryId',
                        name: 'GameCountriesEdit',
                        component: GameCountriesEdit
                    },
                ]
            },
            {
                path: 'variables',
                name: 'GameVariables',
                component: GameVariables,
                children: [
                    {
                        path: '',
                        name: 'GameVariablesList',
                        component: GameVariablesList
                    },
                    {
                        path: 'add',
                        name: 'GameVariablesAdd',
                        component: GameVariablesAdd
                    },
                    {
                        path: 'edit/:variablesPresetId',
                        name: 'GameVariablesEdit',
                        component: GameVariablesEdit
                    },
                ]
            },
            {
                path: 'languages',
                name: 'GameLanguages',
                component: GameLanguages,
                children: [
                    {
                        path: '',
                        name: 'GameLanguagesList',
                        component: GameLanguagesList
                    },
                    {
                        path: 'add',
                        name: 'GameLanguagesAdd',
                        component: GameLanguagesAdd
                    },
                    {
                        path: 'edit/:id',
                        name: 'GameLanguagesEdit',
                        component: GameLanguagesEdit
                    },
                ]
            },
            {
                path: 'admin_languages',
                name: 'AdminLanguages',
                component: AdminLanguages,
                children: [
                    {
                        path: '',
                        name: 'AdminLanguagesList',
                        component: AdminLanguagesList
                    },
                    {
                        path: 'add',
                        name: 'AdminLanguagesAdd',
                        component: AdminLanguagesAdd
                    },
                    {
                        path: 'edit/:id',
                        name: 'AdminLanguagesEdit',
                        component: AdminLanguagesEdit
                    },
                ]
            },


            /* Host Routes */
            {
                path: 'host/games',
                name: 'HostGames',
                component: HostGames,
                children: [
                    {
                        path: '',
                        name: 'HostGamesList',
                        component: HostGamesList
                    },
                    {
                        path: 'add',
                        name: 'HostGamesAdd',
                        component: HostGamesAdd
                    },
                    {
                        path: 'control/:gameId',
                        name: 'HostGamesControl',
                        component: HostGamesControl
                    },
                ]
            },

        ]
    },

    /* Stats */
    {
        path: '/stats/:gameId',
        name: 'Stats',
        component: Stats,
    },

    /* Final */
    {
        path: '/final/:gameId',
        name: 'Final',
        component: Final,
    },
]

const router = new VueRouter({
    routes
})

export default router
